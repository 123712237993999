import { Link } from "gatsby"
import React from "react"

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }

    toggle() {
        this.setState({
            open: !this.state.open
        })
    }

    render() {
        return (<header>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <button className="navbar-toggler"
                        onClick={this.toggle.bind(this)}
                        type="button"
                        aria-controls="navbar-menu"
                        aria-expanded={this.state.open}
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <Link className="navbar-brand" to="/">The Art of Grant Kniffen</Link>

                <div className={"collapse navbar-collapse" + (this.state.open ? ' show' : '') } id="navbar-menu">
                    <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                        <li className="nav-item active">
                            <Link className="nav-link" to="/">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/page/about">About The Artist</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/contact">Contact</Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>)
    }
}
