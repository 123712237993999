/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

function SEO({ description, lang, meta, title, image }) {
  const defaultTitle = 'The Art of Grant Kniffen';
  const metaDescription = description
  const metaTitle = title || defaultTitle

  if(image) {
    meta = meta.concat([
      {
        property: `og:image`,
        content: image,
      },
      {
        property: `twitter:image`,
        content: image
      }
    ]);
  }

  if(metaDescription) {
    meta = meta.concat([
      {
        property: `description`,
        content: metaDescription,
      },
      {
        property: `og:description`,
        content: metaDescription
      },
      {
        property: `twitter:description`,
        content: metaDescription
      }
    ]);
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      defaultTitle={defaultTitle}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        }
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  title: '',
  image: null,
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default SEO
